export const FONT = Uint8Array.of(
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xC7, 0xC7, 0xC7, 0xCF, 0xCF, 0xFF, 0xCF, 0xFF,
    0x93, 0x93, 0x93, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0x93, 0x01, 0x93, 0x93, 0x93, 0x01, 0x93, 0xFF,
    0xEF, 0x83, 0x2F, 0x83, 0xE9, 0x03, 0xEF, 0xFF,
    0x9D, 0x5B, 0x37, 0xEF, 0xD9, 0xB5, 0x73, 0xFF,
    0x8F, 0x27, 0x27, 0x8F, 0x25, 0x33, 0x81, 0xFF,
    0xCF, 0xCF, 0xCF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xF3, 0xE7, 0xCF, 0xCF, 0xCF, 0xE7, 0xF3, 0xFF,
    0x9F, 0xCF, 0xE7, 0xE7, 0xE7, 0xCF, 0x9F, 0xFF,
    0xFF, 0x93, 0xC7, 0x01, 0xC7, 0x93, 0xFF, 0xFF,
    0xFF, 0xE7, 0xE7, 0x81, 0xE7, 0xE7, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xCF, 0xCF, 0x9F,
    0xFF, 0xFF, 0xFF, 0x81, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xCF, 0xCF, 0xFF,
    0xFD, 0xFB, 0xF7, 0xEF, 0xDF, 0xBF, 0x7F, 0xFF,
    0xC7, 0xB3, 0x39, 0x39, 0x39, 0x9B, 0xC7, 0xFF,
    0xE7, 0xC7, 0xE7, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0x83, 0x39, 0xF1, 0xC3, 0x87, 0x1F, 0x01, 0xFF,
    0x81, 0xF3, 0xE7, 0xC3, 0xF9, 0x39, 0x83, 0xFF,
    0xE3, 0xC3, 0x93, 0x33, 0x01, 0xF3, 0xF3, 0xFF,
    0x03, 0x3F, 0x03, 0xF9, 0xF9, 0x39, 0x83, 0xFF,
    0xC3, 0x9F, 0x3F, 0x03, 0x39, 0x39, 0x83, 0xFF,
    0x01, 0x39, 0xF3, 0xE7, 0xCF, 0xCF, 0xCF, 0xFF,
    0x87, 0x3B, 0x1B, 0x87, 0x61, 0x79, 0x83, 0xFF,
    0x83, 0x39, 0x39, 0x81, 0xF9, 0xF3, 0x87, 0xFF,
    0xFF, 0xCF, 0xCF, 0xFF, 0xCF, 0xCF, 0xFF, 0xFF,
    0xFF, 0xCF, 0xCF, 0xFF, 0xCF, 0xCF, 0x9F, 0xFF,
    0xF3, 0xE7, 0xCF, 0x9F, 0xCF, 0xE7, 0xF3, 0xFF,
    0xFF, 0xFF, 0x01, 0xFF, 0x01, 0xFF, 0xFF, 0xFF,
    0x9F, 0xCF, 0xE7, 0xF3, 0xE7, 0xCF, 0x9F, 0xFF,
    0x83, 0x01, 0x39, 0xF3, 0xC7, 0xFF, 0xC7, 0xFF,
    0x83, 0x7D, 0x45, 0x55, 0x41, 0x7F, 0x83, 0xFF,
    0xC7, 0x93, 0x39, 0x39, 0x01, 0x39, 0x39, 0xFF,
    0x03, 0x39, 0x39, 0x03, 0x39, 0x39, 0x03, 0xFF,
    0xC3, 0x99, 0x3F, 0x3F, 0x3F, 0x99, 0xC3, 0xFF,
    0x07, 0x33, 0x39, 0x39, 0x39, 0x33, 0x07, 0xFF,
    0x01, 0x3F, 0x3F, 0x03, 0x3F, 0x3F, 0x01, 0xFF,
    0x01, 0x3F, 0x3F, 0x03, 0x3F, 0x3F, 0x3F, 0xFF,
    0xC1, 0x9F, 0x3F, 0x31, 0x39, 0x99, 0xC1, 0xFF,
    0x39, 0x39, 0x39, 0x01, 0x39, 0x39, 0x39, 0xFF,
    0x81, 0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0xF9, 0xF9, 0xF9, 0xF9, 0xF9, 0x39, 0x83, 0xFF,
    0x39, 0x33, 0x27, 0x0F, 0x07, 0x23, 0x31, 0xFF,
    0x9F, 0x9F, 0x9F, 0x9F, 0x9F, 0x9F, 0x81, 0xFF,
    0x39, 0x11, 0x01, 0x01, 0x29, 0x39, 0x39, 0xFF,
    0x39, 0x19, 0x09, 0x01, 0x21, 0x31, 0x39, 0xFF,
    0x83, 0x39, 0x39, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0x03, 0x39, 0x39, 0x39, 0x03, 0x3F, 0x3F, 0xFF,
    0x83, 0x39, 0x39, 0x39, 0x21, 0x33, 0x85, 0xFF,
    0x03, 0x39, 0x39, 0x31, 0x07, 0x23, 0x31, 0xFF,
    0x87, 0x33, 0x3F, 0x83, 0xF9, 0x39, 0x83, 0xFF,
    0x81, 0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0xFF,
    0x39, 0x39, 0x39, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0x39, 0x39, 0x39, 0x11, 0x83, 0xC7, 0xEF, 0xFF,
    0x39, 0x39, 0x29, 0x01, 0x01, 0x11, 0x39, 0xFF,
    0x39, 0x11, 0x83, 0xC7, 0x83, 0x11, 0x39, 0xFF,
    0x99, 0x99, 0x99, 0xC3, 0xE7, 0xE7, 0xE7, 0xFF,
    0x01, 0xF1, 0xE3, 0xC7, 0x8F, 0x1F, 0x01, 0xFF,
    0xC3, 0xCF, 0xCF, 0xCF, 0xCF, 0xCF, 0xC3, 0xFF,
    0x7F, 0xBF, 0xDF, 0xEF, 0xF7, 0xFB, 0xFD, 0xFF,
    0x87, 0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0x87, 0xFF,
    0xC7, 0x93, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0x01,
    0xEF, 0xF7, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0x83, 0xF9, 0x81, 0x39, 0x81, 0xFF,
    0x3F, 0x3F, 0x03, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xFF, 0xFF, 0x81, 0x3F, 0x3F, 0x3F, 0x81, 0xFF,
    0xF9, 0xF9, 0x81, 0x39, 0x39, 0x39, 0x81, 0xFF,
    0xFF, 0xFF, 0x83, 0x39, 0x01, 0x3F, 0x83, 0xFF,
    0xF1, 0xE7, 0x81, 0xE7, 0xE7, 0xE7, 0xE7, 0xFF,
    0xFF, 0xFF, 0x81, 0x39, 0x39, 0x81, 0xF9, 0x83,
    0x3F, 0x3F, 0x03, 0x39, 0x39, 0x39, 0x39, 0xFF,
    0xE7, 0xFF, 0xC7, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0xF3, 0xFF, 0xE3, 0xF3, 0xF3, 0xF3, 0xF3, 0x87,
    0x3F, 0x3F, 0x31, 0x03, 0x07, 0x23, 0x31, 0xFF,
    0xC7, 0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0xFF, 0xFF, 0x03, 0x49, 0x49, 0x49, 0x49, 0xFF,
    0xFF, 0xFF, 0x03, 0x39, 0x39, 0x39, 0x39, 0xFF,
    0xFF, 0xFF, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xFF, 0xFF, 0x03, 0x39, 0x39, 0x03, 0x3F, 0x3F,
    0xFF, 0xFF, 0x81, 0x39, 0x39, 0x81, 0xF9, 0xF9,
    0xFF, 0xFF, 0x91, 0x8F, 0x9F, 0x9F, 0x9F, 0xFF,
    0xFF, 0xFF, 0x83, 0x3F, 0x83, 0xF9, 0x03, 0xFF,
    0xE7, 0xE7, 0x81, 0xE7, 0xE7, 0xE7, 0xE7, 0xFF,
    0xFF, 0xFF, 0x39, 0x39, 0x39, 0x39, 0x81, 0xFF,
    0xFF, 0xFF, 0x99, 0x99, 0x99, 0xC3, 0xE7, 0xFF,
    0xFF, 0xFF, 0x49, 0x49, 0x49, 0x49, 0x81, 0xFF,
    0xFF, 0xFF, 0x39, 0x01, 0xC7, 0x01, 0x39, 0xFF,
    0xFF, 0xFF, 0x39, 0x39, 0x39, 0x81, 0xF9, 0x83,
    0xFF, 0xFF, 0x01, 0xE3, 0xC7, 0x8F, 0x01, 0xFF,
    0xF3, 0xE7, 0xE7, 0xCF, 0xE7, 0xE7, 0xF3, 0xFF,
    0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0xE7, 0xFF,
    0x9F, 0xCF, 0xCF, 0xE7, 0xCF, 0xCF, 0x9F, 0xFF,
    0xFF, 0xFF, 0x8F, 0x45, 0xE3, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0x93, 0x93, 0xFF,
    0x83, 0x11, 0x29, 0x39, 0x29, 0x29, 0x83, 0xFF,
    0x83, 0x31, 0x29, 0x31, 0x29, 0x31, 0x83, 0xFF,
    0x83, 0x11, 0x11, 0x7D, 0x11, 0x11, 0x83, 0xFF,
    0x83, 0x01, 0x01, 0x7D, 0x01, 0x01, 0x83, 0xFF,
    0x83, 0x11, 0x21, 0x7D, 0x21, 0x11, 0x83, 0xFF,
    0x83, 0x11, 0x09, 0x7D, 0x09, 0x11, 0x83, 0xFF,
    0x83, 0x11, 0x39, 0x55, 0x11, 0x11, 0x83, 0xFF,
    0x83, 0x11, 0x11, 0x55, 0x39, 0x11, 0x83, 0xFF,
    0x83, 0x11, 0x39, 0x7D, 0x39, 0x11, 0x83, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xE7, 0xFF, 0xE7, 0xE7, 0xC7, 0xC7, 0xC7, 0xFF,
    0xEF, 0x83, 0x29, 0x2F, 0x29, 0x83, 0xEF, 0xFF,
    0xC3, 0x99, 0x9F, 0x03, 0x9F, 0x9F, 0x01, 0xFF,
    0xFF, 0xA5, 0xDB, 0xDB, 0xDB, 0xA5, 0xFF, 0xFF,
    0x99, 0x99, 0xC3, 0x81, 0xE7, 0x81, 0xE7, 0xFF,
    0xE7, 0xE7, 0xE7, 0xFF, 0xE7, 0xE7, 0xE7, 0xFF,
    0xC3, 0x99, 0x87, 0xDB, 0xE1, 0x99, 0xC3, 0xFF,
    0x93, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xC3, 0xBD, 0x66, 0x5E, 0x5E, 0x66, 0xBD, 0xC3,
    0x87, 0xC3, 0x93, 0xC3, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xC9, 0x93, 0x27, 0x93, 0xC9, 0xFF, 0xFF,
    0xFF, 0xFF, 0x81, 0xF9, 0xF9, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xC3, 0xBD, 0x46, 0x5A, 0x46, 0x5A, 0xBD, 0xC3,
    0x83, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xEF, 0xD7, 0xEF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xE7, 0xE7, 0x81, 0xE7, 0xE7, 0xFF, 0x81, 0xFF,
    0xC7, 0xF3, 0xE7, 0xC3, 0xFF, 0xFF, 0xFF, 0xFF,
    0xC3, 0xE7, 0xF3, 0xC7, 0xFF, 0xFF, 0xFF, 0xFF,
    0xF7, 0xEF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0x33, 0x33, 0x33, 0x33, 0x09, 0x3F,
    0xC1, 0x95, 0xB5, 0x95, 0xC1, 0xF5, 0xF5, 0xFF,
    0xFF, 0xFF, 0xFF, 0xCF, 0xCF, 0xFF, 0xFF, 0xFF,
    0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xF7, 0xCF,
    0xE7, 0xC7, 0xE7, 0xC3, 0xFF, 0xFF, 0xFF, 0xFF,
    0xC7, 0x93, 0x93, 0xC7, 0xFF, 0xFF, 0xFF, 0xFF,
    0xFF, 0x27, 0x93, 0xC9, 0x93, 0x27, 0xFF, 0xFF,
    0xBD, 0x3B, 0xB7, 0xAD, 0xD9, 0xB1, 0x7D, 0xFF,
    0xBD, 0x3B, 0xB7, 0xA9, 0xDD, 0xBB, 0x71, 0xFF,
    0x1D, 0xBB, 0xD7, 0x2D, 0xD9, 0xB1, 0x7D, 0xFF,
    0xC7, 0xFF, 0xC7, 0x9F, 0x39, 0x01, 0x83, 0xFF,
    0xDF, 0xEF, 0xC7, 0x93, 0x39, 0x01, 0x39, 0xFF,
    0xF7, 0xEF, 0xC7, 0x93, 0x39, 0x01, 0x39, 0xFF,
    0xC7, 0x93, 0xC7, 0x93, 0x39, 0x01, 0x39, 0xFF,
    0xCB, 0xA7, 0xC7, 0x93, 0x39, 0x01, 0x39, 0xFF,
    0x93, 0xFF, 0xC7, 0x93, 0x39, 0x01, 0x39, 0xFF,
    0xEF, 0xD7, 0xC7, 0x93, 0x39, 0x01, 0x39, 0xFF,
    0xC1, 0x87, 0x27, 0x21, 0x07, 0x27, 0x21, 0xFF,
    0xC3, 0x99, 0x3F, 0x3F, 0x99, 0xC3, 0xF7, 0xCF,
    0xDF, 0xEF, 0x01, 0x3F, 0x03, 0x3F, 0x01, 0xFF,
    0xF7, 0xEF, 0x01, 0x3F, 0x03, 0x3F, 0x01, 0xFF,
    0xC7, 0x93, 0x01, 0x3F, 0x03, 0x3F, 0x01, 0xFF,
    0x93, 0xFF, 0x01, 0x3F, 0x03, 0x3F, 0x01, 0xFF,
    0xEF, 0xF7, 0x81, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0xF7, 0xEF, 0x81, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0xE7, 0xC3, 0x81, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0x99, 0xFF, 0x81, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0x87, 0x93, 0x99, 0x09, 0x99, 0x93, 0x87, 0xFF,
    0xCB, 0xA7, 0x19, 0x09, 0x01, 0x21, 0x31, 0xFF,
    0xDF, 0xEF, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xF7, 0xEF, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xC7, 0x93, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xCB, 0xA7, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0x93, 0xFF, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xFF, 0xBB, 0xD7, 0xEF, 0xD7, 0xBB, 0xFF, 0xFF,
    0x83, 0x39, 0x31, 0x29, 0x19, 0x39, 0x83, 0xFF,
    0xDF, 0xEF, 0x39, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xF7, 0xEF, 0x39, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xC7, 0x93, 0xFF, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0x93, 0xFF, 0x39, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xF7, 0xEF, 0x99, 0x99, 0xC3, 0xE7, 0xE7, 0xFF,
    0x3F, 0x03, 0x39, 0x39, 0x39, 0x03, 0x3F, 0xFF,
    0xC3, 0x99, 0x99, 0x93, 0x99, 0x89, 0x93, 0xFF,
    0xDF, 0xEF, 0x83, 0xF9, 0x81, 0x39, 0x81, 0xFF,
    0xF7, 0xEF, 0x83, 0xF9, 0x81, 0x39, 0x81, 0xFF,
    0xC7, 0x93, 0x83, 0xF9, 0x81, 0x39, 0x81, 0xFF,
    0xCB, 0xA7, 0x83, 0xF9, 0x81, 0x39, 0x81, 0xFF,
    0x93, 0xFF, 0x83, 0xF9, 0x81, 0x39, 0x81, 0xFF,
    0xEF, 0xD7, 0x83, 0xF9, 0x81, 0x39, 0x81, 0xFF,
    0xFF, 0xFF, 0x83, 0xE9, 0x81, 0x2F, 0x83, 0xFF,
    0xFF, 0xFF, 0x81, 0x3F, 0x3F, 0x81, 0xF7, 0xCF,
    0xDF, 0xEF, 0x83, 0x39, 0x01, 0x3F, 0x83, 0xFF,
    0xF7, 0xEF, 0x83, 0x39, 0x01, 0x3F, 0x83, 0xFF,
    0xC7, 0x93, 0x83, 0x39, 0x01, 0x3F, 0x83, 0xFF,
    0x93, 0xFF, 0x83, 0x39, 0x01, 0x3F, 0x83, 0xFF,
    0xDF, 0xEF, 0xFF, 0xC7, 0xE7, 0xE7, 0x81, 0xFF,
    0xF7, 0xEF, 0xFF, 0xC7, 0xE7, 0xE7, 0x81, 0xFF,
    0xC7, 0x93, 0xFF, 0xC7, 0xE7, 0xE7, 0x81, 0xFF,
    0x93, 0xFF, 0xC7, 0xE7, 0xE7, 0xE7, 0x81, 0xFF,
    0x9B, 0x87, 0x67, 0x83, 0x39, 0x39, 0x83, 0xFF,
    0xCB, 0xA7, 0x03, 0x39, 0x39, 0x39, 0x39, 0xFF,
    0xDF, 0xEF, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xF7, 0xEF, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xC7, 0x93, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xCB, 0xA7, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0x93, 0xFF, 0x83, 0x39, 0x39, 0x39, 0x83, 0xFF,
    0xFF, 0xE7, 0xFF, 0x81, 0xFF, 0xE7, 0xFF, 0xFF,
    0xFF, 0xFF, 0x83, 0x31, 0x29, 0x19, 0x83, 0xFF,
    0xDF, 0xEF, 0x39, 0x39, 0x39, 0x39, 0x81, 0xFF,
    0xF7, 0xEF, 0x39, 0x39, 0x39, 0x39, 0x81, 0xFF,
    0xC7, 0x93, 0xFF, 0x39, 0x39, 0x39, 0x81, 0xFF,
    0x93, 0xFF, 0x39, 0x39, 0x39, 0x39, 0x81, 0xFF,
    0xF7, 0xEF, 0x39, 0x39, 0x39, 0x81, 0xF9, 0x83,
    0x3F, 0x3F, 0x03, 0x39, 0x39, 0x03, 0x3F, 0x3F,
    0x93, 0xFF, 0x39, 0x39, 0x39, 0x81, 0xF9, 0x83,
);
